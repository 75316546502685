<gipi-abstract-form id="dialogIntegrations"
                    name="dialogIntegrations"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogIntegrations">
        Integrações
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px"
         style="height: 100%;">
        <gipi-tab-group class="tab-group-integration">
            <gipi-tab label="WhatsApp"
                      id="tabWhatsapp"
                      name="tabWhatsapp"
                      svgIcon="whatsapp"
                      class="tab-integration"
                      [disabled]="loading">
                <ng-container *ngTemplateOutlet="twilioTemplate"> </ng-container>
            </gipi-tab>
            <gipi-tab label="Connect"
                      id="tabConnect"
                      name="tabConnect"
                      svgIcon="panel_integration_connect"
                      class="tab-integration"
                      [disabled]="loading"
                      (selectionChange)="setAutoCompleteInPassword()">
                <ng-container *ngTemplateOutlet="connectTemplate"> </ng-container>
            </gipi-tab>
            <gipi-tab label="Scanntech"
                      id="tabScanntech"
                      name="tabScanntech"
                      svgIcon="panel_integration_scanntech"
                      class="tab-integration"
                      [disabled]="loading">
                <ng-container *ngTemplateOutlet="scanntechTemplate"> </ng-container>
            </gipi-tab>
        </gipi-tab-group>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         fxLayoutAlign="end center"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnIntegrationsConfirm"
                     [disabled]="loading"
                     [loading]="loading"
                     (click)="confirm()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnIntegrationsReturn"
                     (click)="close()"
                     fxFlex="20"
                     fxFlex.lt-sm="30">
        </gipi-button>
    </div>
</gipi-abstract-form>

<ng-template #twilioTemplate>
    <div fxLayout="column"
         fxLayoutGap="16px">
        <div id="panelTwilioInfoInvoice"
             class="content-information">
            <label for="contentInformation">
                Informações da fatura

                <button id="btnIsClosedInvoiceTwilio"
                        name="btnIsClosedInvoiceTwilio"
                        class="button-toggle-closed-invoice"
                        aria-label="Exibir faturas em aberto/pagas"
                        (click)="isClosedInvoiceTwilio = !isClosedInvoiceTwilio; findNotificationStatement()">
                    <mat-icon svgIcon="eye"> </mat-icon>
                    {{ isClosedInvoiceTwilio ? 'Exibir faturas em aberto' : 'Exibir faturas pagas' }}
                </button>
            </label>
            <div id="contentInformation"
                 fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutGap="16px">
                <gipi-month-year-picker label="Período"
                                        help="Considera do primeiro ao último dia do mês"
                                        id="panelTwilioPeriodicity"
                                        name="panelTwilioPeriodicity"
                                        [required]="true"
                                        [disabled]="loading"
                                        [calendarView]="'both'"
                                        [(ngModel)]="panelTwilioPeriodicity"
                                        (ngModelChange)="findNotificationStatement()"
                                        fxFlex>
                </gipi-month-year-picker>

                <!-- Qtd. mensagens -->
                <div class="input-info-invoice">
                    <label>
                        Quantidade
                        <mat-icon matTooltip="Quantidade de mensagens enviadas">
                            help
                        </mat-icon>
                    </label>
                    <div> {{ panelTwilioNotificationStatement?.quantity }} </div>
                </div>

                <!-- Valor total -->
                <div class="input-info-invoice">
                    <label>
                        Valor total
                        <mat-icon matTooltip="Valor total = (Quantidade * Valor unitário)">
                            help
                        </mat-icon>
                    </label>
                    <div> {{ panelTwilioNotificationStatement?.price | currency:'BRL':'symbol':'1.2-2' }} </div>
                </div>

                <!-- Status -->
                <div class="input-info-invoice">
                    <label>
                        Status
                        <mat-icon matTooltip="Indica se o período está em aberto ou se foi pago">
                            help
                        </mat-icon>
                    </label>
                    <div> {{ (panelTwilioStatusInvoice | async) }}</div>
                </div>
            </div>
        </div>

        <div id="panelTwilioInfoSender"
             class="content-information">
            <label for="contentInformation">
                Informações do remetente
            </label>
            <div id="contentInformation"
                 fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutGap="16px">
                <gipi-input-phone label="Telefone"
                                  id="panelTwilioPhoneNumber"
                                  name="panelTwilioPhoneNumber"
                                  [placeholder]="'(00) 0 0000-0000'"
                                  [required]="true"
                                  [disabled]="loading"
                                  [(ngModel)]="customerNotificationConfig.phoneNumber"
                                  fxFlex="24"
                                  fxFlex.lt-md="100">
                </gipi-input-phone>
                <itss-input label="E-mail"
                            id="panelTwilioEmail"
                            name="panelTwilioEmail"
                            [type]="'email'"
                            [placeholder]="'exemplo@email.com'"
                            [required]="true"
                            [disabled]="loading"
                            [lowerCase]="true"
                            [(ngModel)]="customerNotificationConfig.emailCustomer"
                            (focusout)="validateEmail(customerNotificationConfig.emailCustomer)"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
            </div>
        </div>

        <div id="panelTwilioOptionsMessages">
            <div *ngFor="let option of panelTwilioOptions; let i = index;"
                 [attr.id]="option.id"
                 [class.selected]="option.selected"
                 class="panel-notification">
                <div class="informations">
                    <strong> {{ option.title }} </strong>
                    <small> {{ option.description }} </small>
                </div>

                <gipi-slide-toggle [id]="'twilioOption' + i"
                                   [name]="'twilioOption' + i"
                                   [(ngModel)]="option.selected">
                </gipi-slide-toggle>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #connectTemplate>
    <div id="panelConnectInfo"
         fxLayout="column"
         fxLayoutGap="32px">
        <div id="panelConnectInfoCredentials"
             class="content-information">
            <label for="contentInformation">
                Informações das credenciais
            </label>
            <div id="contentInformation"
                 fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutGap="16px">
                <p>
                    Para realizar a integração, é necessário obter as credenciais. Por favor, entre em contato com a
                    <a id="panelConnectContactConnectSA"
                       href="https://www.connectsa.com.br/seja-cliente-gipi-sistemas/"
                       target="_blank"
                       class="link-connect">
                        Connect SA
                    </a>
                    para solicitá-las. Após receber os dados necessários, você poderá inseri-los abaixo e concluir o
                    processo.
                </p>
            </div>
        </div>

        <div id="panelConnectInputsCredentials"
             fxLayout="column"
             fxLayoutGap="16px">
            <div fxLayout="row"
                 fxLayoutGap="16px"
                 fxFlex>
                <gipi-input-select-enum label="Situação"
                                        id="panelConnectEnabled"
                                        name="panelConnectEnabled"
                                        [required]="true"
                                        [disabled]="loading && (panelConnectIsNewRecord | async)"
                                        [valuesExclud]="[]"
                                        [enum]="panelConnectOptionActiveSituationEnum"
                                        [(ngModel)]="panelConnectOptionActiveSituationValue"
                                        fxFlex
                                        fxFlex.lt-md="100">
                </gipi-input-select-enum>
                <itss-input label="ID do cliente"
                            id="panelConnectIdClient"
                            name="panelConnectIdClient"
                            [required]="(pannelConnectIsActive | async)"
                            [disabled]="loading"
                            [(ngModel)]="connectConfig.idClient"
                            [max]="254"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
                <itss-input label="Login"
                            id="panelConnectLogin"
                            name="panelConnectLogin"
                            [required]="(pannelConnectIsActive | async)"
                            [disabled]="loading"
                            [(ngModel)]="connectConfig.login"
                            [max]="254"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>

                <itss-input label="Senha"
                            #panelConnectInputPassword
                            id="panelConnectPassword"
                            name="panelConnectPassword"
                            [type]="panelConnectBtnViewPassword ? 'text' : 'password'"
                            [required]="(pannelConnectIsActive | async)"
                            [disabled]="loading"
                            [(ngModel)]="connectConfig.password"
                            [max]="254"
                            fxFlex
                            fxFlex.lt-md="100">
                    <ng-template #suffix>
                        <gipi-button [tooltip]="panelConnectBtnViewPassword ? 'Ocultar senha' : 'Exibir senha'"
                                     gipi-icon
                                     id="panelConnectBtnViewPassword"
                                     name="panelConnectBtnViewPassword"
                                     [disabled]="loading"
                                     [forInput]="true"
                                     [svgIcon]="panelConnectBtnViewPassword ? 'eye' : 'eye_off'"
                                     (click)="panelConnectBtnViewPassword = !panelConnectBtnViewPassword">
                        </gipi-button>
                    </ng-template>
                </itss-input>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #scanntechTemplate>
    <div fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxFlex>
            <gipi-input-select-enum label="Situação"
                                    id="panelScanntechEnabled"
                                    name="panelScanntechEnabled"
                                    [required]="true"
                                    [disabled]="loading"
                                    [valuesExclud]="[]"
                                    [enum]="panelScanntechOptionActiveSituationEnum"
                                    [(ngModel)]="panelScanntechOptionActiveSituationValue"
                                    fxFlex
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
            <itss-input label="Código da empresa"
                        id="panelScanntechCompanyCode"
                        name="panelScanntechCompanyCode"
                        tooltip="Código da empresa (companyCode) fornecido pela Scanntech."
                        [required]="(pannelScanntechIsActive | async)"
                        [disabled]="loading"
                        [(ngModel)]="scanntechConfig.companyCode"
                        [mask]="'0*'"
                        [min]="1"
                        [max]="254"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Código da loja"
                        id="panelScanntechStoreCode"
                        name="panelScanntechStoreCode"
                        tooltip="O código da loja representa o número da loja. Caso tenha mais de uma loja, use os números correspondentes, como 1, 2, 3, etc."
                        [required]="(pannelScanntechIsActive | async)"
                        [disabled]="loading"
                        [(ngModel)]="scanntechConfig.storeCode"
                        [mask]="'0*'"
                        [min]="1"
                        [max]="254"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
        </div>

        <div fxLayout="row"
             fxLayoutGap="16px"
             fxFlex>
            <itss-input label="Intervalo de sincronização"
                        id="panelScanntechIntervalBetweenSyncs"
                        name="panelScanntechIntervalBetweenSyncs"
                        tooltip="Intervalo de tempo em minutos entre cada sincronização."
                        [required]="(pannelScanntechIsActive | async)"
                        [disabled]="loading"
                        [(ngModel)]="scanntechConfig.intervalBetweenSyncs"
                        [mask]="'0*'"
                        [min]="1"
                        [maxlength]="7"
                        fxFlex
                        fxFlex.lt-md="100">
                <ng-template #suffix>
                    <div class="scanntech-suffix-time">
                        Min
                    </div>
                </ng-template>
            </itss-input>
            <itss-input label="Qtd vendas por lote"
                        id="panelScanntechSalesQuantityPerLot"
                        name="panelScanntechSalesQuantityPerLot"
                        tooltip="Quantidade de vendas enviadas em cada sincronização."
                        placeholder="Ex: 1"
                        [required]="(pannelScanntechIsActive | async)"
                        [disabled]="loading"
                        [(ngModel)]="scanntechConfig.salesQuantityPerLot"
                        [mask]="'0*'"
                        [min]="1"
                        [max]="254"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <itss-input label="Horário envio fechamento"
                        id="panelScanntechTimeForSendingPendingClosings"
                        name="panelScanntechTimeForSendingPendingClosings"
                        tooltip="Horário para realizar o envio do fechamento para Scanntech."
                        placeholder="00:00"
                        [required]="(pannelScanntechIsActive | async)"
                        [disabled]="loading"
                        [(ngModel)]="scanntechConfig.timeForSendingPendingClosings"
                        [mask]="'00:00'"
                        fxFlex
                        fxFlex.lt-md="100">
            </itss-input>
            <gipi-datepicker label="Data inicio da sincronização"
                             id="panelScanntechDataSendingStartDate"
                             name="panelScanntechDataSendingStartDate"
                             [disabled]="loading"
                             [(ngModel)]="scanntechConfig.dataSendingStartDate"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-datepicker>
        </div>

        <div id="panelScanntechInfoAuth"
             class="content-information">
            <label for="contentInformation">
                Informações de autenticação
            </label>
            <div id="contentInformation"
                 fxLayout="row"
                 fxLayout.lt-md="column"
                 fxLayoutGap="16px">
                <itss-input label="Usuário"
                            id="panelScanntechUserName"
                            name="panelScanntechUserName"
                            [required]="(pannelScanntechIsActive | async)"
                            [disabled]="loading"
                            [(ngModel)]="scanntechConfig.userName"
                            [max]="254"
                            fxFlex
                            fxFlex.lt-md="100">
                </itss-input>
                <itss-input label="Senha"
                            #panelScanntechInputPassword
                            id="panelScanntechPassword"
                            name="panelScanntechPassword"
                            [autocomplete]="'new-password'"
                            [type]="panelScanntechBtnViewPassword ? 'text' : 'password'"
                            [required]="(pannelScanntechIsActive | async)"
                            [disabled]="loading"
                            [(ngModel)]="scanntechConfig.password"
                            [max]="254"
                            fxFlex
                            fxFlex.lt-md="100">
                    <ng-template #suffix>
                        <gipi-button [tooltip]="panelConnectBtnViewPassword ? 'Ocultar senha' : 'Exibir senha'"
                                     gipi-icon
                                     id="panelConnectBtnViewPassword"
                                     name="panelConnectBtnViewPassword"
                                     [disabled]="loading"
                                     [forInput]="true"
                                     [svgIcon]="panelConnectBtnViewPassword ? 'eye' : 'eye_off'"
                                     (click)="panelConnectBtnViewPassword = !panelConnectBtnViewPassword">
                        </gipi-button>
                    </ng-template>
                </itss-input>
            </div>
        </div>

        <gipi-expansion-panel panelTitle="URLs"
                              id="scanntechPanelUrl"
                              name="scanntechPanelUrl"
                              svgIcon="scanntech_url"
                              [iconSize]="24"
                              [disabled]="loading"
                              fxFlex>
            <div fxLayout="column"
                 fxFlex
                 fxLayoutGap="16px">
                <ng-container *ngFor="let url of scanntechConfig?.urlList; let i = index">
                    <div fxLayout="column"
                         fxFlex
                         fxLayoutGap="16px"
                         class="group-panel-register"
                         *ngIf="scanntechConfig?.urlList && (scanntechConfig?.urlList.length > 0) && !loading"
                         [attr.id]="'groupUrl' + i">

                        <gipi-button tooltip="Excluir"
                                     id="buttonScanntechDeleteUrl"
                                     name="buttonScanntechDeleteUrl"
                                     gipi-icon
                                     class="btn-delete-group"
                                     icon="close"
                                     *ngIf="!loading"
                                     (click)="scanntechDeleteUrl(url)">
                        </gipi-button>

                        <div fxLayout="row"
                             fxLayoutAlign="start"
                             fxLayoutGap="16px"
                             fxLayout.lt-sm="column"
                             fxLayout.lt-sm="column">
                            <itss-input label="URL"
                                        id="inputScanntechUrl"
                                        name="inputScanntechUrl"
                                        [placeholder]="'https://exemplo.com'"
                                        [required]="true"
                                        [disabled]="loading"
                                        [(ngModel)]="url.url"
                                        fxFlex
                                        fxFlex.lt-sm="100">
                            </itss-input>
                            <itss-checkbox label="URL padrão"
                                           id="scanntechDefaultUrl"
                                           name="scanntechDefaultUrl"
                                           class="checkbox-center"
                                           color="primary"
                                           [disabled]="loading"
                                           [ngModel]="url.defaultURL"
                                           (change)="scanntechValidateDefaultUrl(url)"
                                           fxFlex="20"
                                           fxFlex.lt-sm="30">
                            </itss-checkbox>
                        </div>
                    </div>
                </ng-container>

                <div class="group-panel-register new-group-panel-register"
                     id="buttonScanntechNewUrl"
                     id="buttonScanntechNewUrl"
                     *ngIf="!loading && (scanntechConfig?.urlList.length >= 3)"
                     (click)="scanntechAddNewUrl()">
                    <label> + Adicionar URL </label>
                </div>
            </div>
        </gipi-expansion-panel>
    </div>
</ng-template>
